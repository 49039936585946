import { useRef } from 'react';
import Banner from 'components/Banner';
// import Blog from 'components/Blog';
import OurValues from 'components/OurValues';
import Contact from 'components/Contact';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import Project from 'components/Project';
import Service from 'components/Service';
import Testimonial from 'components/Testimonial';
// import Brands from 'components/Brands';

const Home = () => {

	const homeRef = useRef<HTMLDivElement>(null)
	const servicesRef = useRef<HTMLDivElement>(null)
	const ourValuesRef = useRef<HTMLDivElement>(null)
	const contactUsRef = useRef<HTMLDivElement>(null)

	return (
		<>
			<div ref={homeRef}>
				<Navbar
					homeRef={homeRef}
					servicesRef={servicesRef}
					ourValuesRef={ourValuesRef}
					contactUsRef={contactUsRef}
				/>
			</div>

			<Banner />

			<div ref={servicesRef}>
				<Service />
			</div>

			<div ref={ourValuesRef}>
				<OurValues />
			</div>

			<Testimonial />

			<Project />

			<div ref={contactUsRef}>
				<Contact />
			</div>

			{/* <Brands /> */}

			{/* <Blog /> */}

			<Footer />
		</>
	);
};

export default Home;
