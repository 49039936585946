import React from 'react';

const Banner = () => (
		<div className='banner-area bg-relative banner-area-3'>
			<div className='container custom-container'>
				<div className='bg-gray'>
					<img
						className='img-right'
					src='https://storage.googleapis.com/femto-website-images/banner.jpg'
						alt='img'
					/>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-7 align-self-center'>
								<div className='banner-inner'>
									<h6
										className='subtitle '
										data-aos='fade-right'
										data-aos-delay='100'
										data-aos-duration='1500'
									>
										DESIGN FOR THE FUTURE
									</h6>
									<h2
										className='title '
										data-aos='fade-right'
										data-aos-delay='250'
										data-aos-duration='1500'
									>
										 <span>Crafting</span> Digital Excellence through
										 Innovative<span> Solutions</span>
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

export default Banner;
