import React from 'react';

const Service = () => (
	<div
		className='service-area bg-cover pd-top-120 pd-bottom-90 pd-top-110 pd-bottom-90'
		style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
	>
		<div className='container'>
			<div className='row justify-content-center'>
				<div className='col-xl-6 col-lg-8'>
					<div className='section-title text-center'>
						<h6 className='sub-title'>WHAT WE OFFER</h6>
						<h2 className='title'>
							<span>Committed</span> to Your Success Delivering Exceptional <span>Services</span>

						</h2>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className='col-xl-3 col-md-6'>
					<div className='single-work-process-inner'>
						<div className='thumb mb-3'>
							<img
								src='assets/img/icon/16.svg'
								alt='img'
							/>
						</div>
						<div className='details'>
							<h5 className='mb-3'>Mobile Applications</h5>
							<p className='content mb-3'>
								Transform your ideas into intuitive mobile apps.
								Our experts craft user-friendly,
								feature-rich solutions to engage and captivate your audience effectively.
							</p>
						</div>
					</div>
				</div>
				
				<div className='col-xl-3 col-md-6'>
					<div className='single-work-process-inner'>
						<div className='thumb mb-3'>
							<img
								src='assets/img/icon/icons8-wearable.png'
								alt='img'
								height={65}
							/>
						</div>
						<div className='details'>
							<h5 className='mb-3'>Wearable applications</h5>
							<p className='content mb-3'>
								Enhance lifestyles with wearable apps. Seamlessly integrate health, fitness,
								and convenience into daily routines, fostering well-being and connectivity.
							</p>
						</div>
					</div>
				</div>
				<div className='col-xl-3 col-md-6'>
					<div className='single-work-process-inner'>
						<div className='thumb mb-3'>
							<img
								src='assets/img/icon/17.svg'
								alt='img'
							/>
						</div>
						<div className='details'>
							<h5 className='mb-3'>Web 3.0 Solutions</h5>
							<p className='content mb-3'>
								Discover the future with our Web 3.0 solutions.
								Embrace blockchain, decentralised apps, and immersive experiences for a new era of possibilities.
							</p>
						</div>
					</div>
				</div>
				<div className='col-xl-3 col-md-6'>
					<div className='single-work-process-inner'>
						<div className='thumb mb-4'>
							<img
								src='assets/img/icon/18.svg'
								alt='img'
							/>
						</div>
						<div className='details'>
							<h5 className='mb-3'>Data Science</h5>
							<p className='content mb-3'>
								Elevate your business with our bespoke Machine Learning and Artificial Intelligence services.
								Harness data for smarter decisions and growth
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Service;
