import { RefObject, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface PropsI {
	homeRef?: RefObject<HTMLDivElement>
	servicesRef?: RefObject<HTMLDivElement>
	ourValuesRef?: RefObject<HTMLDivElement>
	contactUsRef?: RefObject<HTMLDivElement>

}
const Navbar = ({
	homeRef,
	servicesRef,
	ourValuesRef,
	contactUsRef
}: PropsI) => {

	const [active, setActive] = useState(false);

	const navigate = useNavigate()
	const location = useLocation()

	const links = [
		{
			label: 'Home',
			ref: homeRef
		},
		{
			label: 'Services',
			ref: servicesRef
		},
		{
			label: 'Our Values',
			ref: ourValuesRef
		},
		{
			label: 'Contact Us',
			ref: contactUsRef
		},
	];

	return (
		<nav className='navbar navbar-area navbar-area-2 navbar-expand-lg bg-white'>
			<div className='container nav-container custom-container'>
				<div className='responsive-mobile-menu'>
					<button
						type='button'
						onClick={() => setActive(!active)}
						className={
							active
								? "menu toggle-btn d-block d-lg-none open"
								: "menu toggle-btn d-block d-lg-none"
						}
						data-target='#itech_main_menu'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='icon-left' />
						<span className='icon-right' />
					</button>
				</div>
				<div className='logo'>
					<Link to='/'>
						<img
							src='assets/img/newlogo.png'
							alt='img'
						/>
					</Link>
				</div>
				<div className='nav-right-part nav-right-part-mobile' />
				<div
					className={
						active
							? "collapse navbar-collapse sopen"
							: "collapse navbar-collapse"
					}
					id='itech_main_menu'
				>
					<ul className='navbar-nav menu-open text-lg-end'>
						{links.map((menu) => (
							<li key={menu.label}>
								<button
									onClick={() => {
										if (location.pathname.includes('blog')) {
											navigate('/')
										} else {
											menu.ref?.current?.scrollIntoView({ behavior: 'smooth' })
										}
									}} type='button'>
									{menu.label}
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
