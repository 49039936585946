import React from 'react';

const OurValues = () => (
	<div className='career-section pd-top-120 '>
		<div className='container'>
			<div className='row justify-content-center'>
				<div className='col-lg-6'>
					<div className='section-title text-center'>
						<h6 className='sub-title'>OUR PHILOSOPHY</h6>
						<h2 className='title'>
						<span>Femto</span>ware&apos;s Guiding Principles Our <span>Values</span>

						</h2>
					</div>
				</div>
			</div>
			<div className='career-wrap-area'>
				<nav>
					<div
						className='nav nav-tabs career-nav-tab'
						id='nav-tab'
						role='tablist'
					>
						<button
							className='nav-link active'
							id='nav-flexibility-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-flexibility'
							type='button'
							role='tab'
							aria-controls='nav-flexibility'
							aria-selected='true'
						>
							Flexibility
						</button>
						<button
							className='nav-link'
							id='nav-expertise-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-expertise'
							type='button'
							role='tab'
							aria-controls='nav-expertise'
							aria-selected='false'
						>
							Expertise
						</button>
						<button
							className='nav-link'
							id='nav-mettle-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-mettle'
							type='button'
							role='tab'
							aria-controls='nav-mettle'
							aria-selected='false'
						>
							Mettle
						</button>
						<button
							className='nav-link'
							id='nav-trust-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-trust'
							type='button'
							role='tab'
							aria-controls='nav-trust'
							aria-selected='false'
						>
							Trust
						</button>
						<button
							className='nav-link'
							id='nav-optimistic-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-optimistic'
							type='button'
							role='tab'
							aria-controls='nav-optimistic'
							aria-selected='false'
						>
							Optimistic
						</button>
					</div>
				</nav>
				<div
					className='tab-content'
					id='nav-tabContent'
				>
					<div
						className='tab-pane fade show active'
						id='nav-flexibility'
						role='tabpanel'
						aria-labelledby='nav-flexibility-tab'
					>
						<div className='career-wrap'>
							<div className='row'>
								<div className='col-lg-5'>
									<img
										src='https://storage.googleapis.com/femto-website-images/flexible.jpg'
										alt='img'
									/>
								</div>
								<div className='col-lg-7'>
									<p className='mb-3'>
									At the core of our ethos is the profound commitment to flexibility.
									 We thrive in the face of change, embracing agility as an inherent strength.
									  Our strategies are rooted in a steadfast dedication to being customer-centric,
									   ensuring that every decision, every innovation,
									   and every solution we offer aligns seamlessly with the evolving needs of our partners.
									    We believe in nurturing growth, not only within our organization but also within
										the partnerships we cultivate. This unwavering focus on adaptability and
										customer-centricity fuels our ability to deliver dynamic and resilient solutions,
										 positioning us at the forefront of progress in an ever-transforming world.
									</p>

								</div>
							</div>
						</div>
					</div>
					<div
						className='tab-pane fade'
						id='nav-expertise'
						role='tabpanel'
						aria-labelledby='nav-expertise-tab'
					>
						<div className='career-wrap'>
							<div className='row'>
								<div className='col-lg-5'>
									<img
										src='https://storage.googleapis.com/femto-website-images/expert.jpg'
										alt='img'
									/>
								</div>
								<div className='col-lg-7'>

									<p className='mb-3'>
									Technical excellence forms the cornerstone of our approach.
									We are driven by a relentless commitment to expertise,
									where knowledge is our most cherished asset. We pledge to continually cultivate our skills,
									 deploying our extensive expertise to craft and deliver top-tier services.
									 Our unwavering focus on people-centric product creation underscores our
									 dedication to excellence. Every project, every solution, bears testament to
									 our profound proficiency, ensuring that we consistently exceed expectations.
									  We believe in the transformative power of our expertise, enabling us to navigate
									  complex challenges and forge a path of innovation, precision, and unwavering commitment
									  to quality in all that we do.
									</p>

								</div>
							</div>
						</div>
					</div>
					<div
						className='tab-pane fade'
						id='nav-mettle'
						role='tabpanel'
						aria-labelledby='nav-mettle-tab'
					>
						<div className='career-wrap'>
							<div className='row'>
								<div className='col-lg-5'>
									<img
										src='https://storage.googleapis.com/femto-website-images/metal.jpg'
										alt='img'
									/>
								</div>
								<div className='col-lg-7'>

									<p className='mb-3'>
									Every venture we undertake embodies a unique mission for us, igniting our
									unwavering passion.
									We are fueled by the prospect of conquering exceptional challenges, customizing
									 solutions to precisely
									align with distinct business requisites. Our dedication lies in orchestrating every project
									as an opportunity
									to manifest our profound expertise, creativity, and unwavering commitment.
									With each endeavor,
									we endeavor to not merely meet but surpass our clients expectations by crafting bespoke
									solutions
									that resonate seamlessly with their distinct objectives and aspirations.
									Our unwavering pursuit of excellence defines our approach, ensuring that we consistently
									 deliver tailored,
									 impactful results for every venture we engage in.
									</p>

								</div>
							</div>
						</div>
					</div>
					<div
						className='tab-pane fade'
						id='nav-trust'
						role='tabpanel'
						aria-labelledby='nav-trust-tab'
					>
						<div className='career-wrap'>
							<div className='row'>
								<div className='col-lg-5'>
									<img
										src='https://storage.googleapis.com/femto-website-images/Together.jpg'
										alt='img'
									/>
								</div>
								<div className='col-lg-7'>

									<p className='mb-3'>
									Our relationships with customers and partners are built upon a bedrock of trust.
									 Our interactions are characterized by the utmost respect, as we firmly believe
									 that trust is the cornerstone of enduring partnerships. We prioritize openness
									 and transparency in all our dealings, valuing the honesty that fosters mutual
									 understanding and collaboration. This commitment to integrity permeates our growth
									  philosophy, ensuring that every engagement is guided by a shared sense of trust.
									   With unwavering transparency, we create an environment where trust flourishes,
									fortifying our bonds, and nurturing the enduring partnerships
									that drive our collective success.
									</p>

								</div>
							</div>
						</div>
					</div>
					<div
						className='tab-pane fade'
						id='nav-optimistic'
						role='tabpanel'
						aria-labelledby='nav-optimistic-tab'
					>
						<div className='career-wrap'>
							<div className='row'>
								<div className='col-lg-5'>
									<img
										src='https://storage.googleapis.com/femto-website-images/confident.jpg'
										alt='img'
									/>
								</div>
								<div className='col-lg-7'>

									<p className='mb-3'>
									We champion a mindset of technological optimism, firmly believing in the
									 boundless potential of technology to confront and surmount the challenges
									  of tomorrow. With an unwavering spirit, we embrace the notion that technology
									   is not only equipped but also empowered to pave the way for a brighter
									   future. Our faith in the transformative power of innovation fuels our
									   commitment to harnessing the latest advancements to create solutions
									   that empower businesses and individuals alike. In this spirit of optimism,
									    we forge ahead, constantly innovating, and adapting
									 to ensure that technology remains a force for positive change,
									 driving progress and unlocking new opportunities for all.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default OurValues;
