import React from 'react';

const Project = () => (
	<div className='project-section pd-top-120'>
		<div className='container'>
			<div className='row justify-content-center'>
				<div className='col-lg-6'>
					<div className='section-title text-center'>
						<h6 className='sub-title'>In Action</h6>
						<h2 className='title'>
							<span>Glimpse</span> of work
						</h2>
					</div>
				</div>
			</div>
			<div
				className='tab-content'
				id='nav-tabContent'
			>
				<div
					className='tab-pane fade show active'
					id='nav-tabs1'
					role='tabpanel'
					aria-labelledby='nav-tabs1-tab'
				>
					<div className='row'>
						<div className='col-md-4'>
							<div className='single-project-inner'>
								<img
									src='assets/img/testimonial/client/hanu/2_gallary.png'
									alt='img'
								/>
							</div>
						</div>
						<div className='col-md-4'>

							<div className='single-project-inner'>
								<img
									src='assets/img/testimonial/client/hanu/2_gallary-two.png'
									alt='img'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='single-project-inner'>
								<img
									src='assets/img/testimonial/client/hanu/2_gallary-three.png'
									alt='img'
								/>
							</div>
							<div className='single-project-inner'>
								<img
									src='assets/img/testimonial/client/hanu/2_gallary-four.png'
									alt='img'
								/>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-4'>
							<div className='single-project-inner'>
								<img
									src='assets/img/testimonial/client/cmg/cmg-2_gallary-two.png'
									alt='img'
								/>
							</div>
						</div>
						<div className='col-md-4'>
							<div className='single-project-inner'>
								<img
									src='assets/img/testimonial/client/cmg/cmg-2_gallary-web.png'
									alt='img'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Project;
