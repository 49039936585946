import { FormEvent, useRef, useState } from "react";
import emailjs from '@emailjs/browser';


const Contact = () => {
	const [formStatus, setFormStatus] = useState<string | null>(null)
	const form = useRef<HTMLFormElement>(null)

	const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
			const { status } = await emailjs.sendForm(
				process.env.REACT_APP_EMAILJS_SERVICE_ID!,
				process.env.REACT_APP_EMAILJS_TEMPLATE_ID!, form.current!,
				process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
			)
			if (status === 200) {
				setFormStatus('Your response has been recorded. Our team will be reaching you soon')
			}
		} catch (error: any) {
			setFormStatus(error.message)
		}
	}

	return (
		<div className='contact-area pd-top-90 pd-bottom-120'>
			<div className='container'>
				<div className='row'>
					<div
						className='col-lg-6 order-lg-end '
						data-aos='fade-left'
						data-aos-delay='200'
						data-aos-duration='1500'
					>
						<div className='about-thumb-inner ms-xl-5 p-xl-5 pt-4 '>
							<img
								className='animate-main-img'
								src='assets/img/about/18.png'
								alt='img'
							/>
							<img
								className='main-img m-4'
								src='https://storage.googleapis.com/femto-website-images/Contactus.jpg'
								alt='img'
							/>
						</div>
					</div>
					<div
						className='col-lg-6 order-lg-first '
						data-aos='fade-right'
						data-aos-delay='200'
						data-aos-duration='1500'
					>
						<div className='section-title mb-0 mt-5 mt-lg-0'>
							<h6 className='sub-title'>GET IN TOUCH</h6>
							<h2 className='title'>
								We are excited to hear from you <span> Feel free to drop us a line!</span>
							</h2>
							<p className='content'>
								Contact Us
							</p>
							<form className='mt-4' ref={form} onSubmit={onSubmit}>
								<div className='row'>
									<div className='col-lg-6'>
										<div className='single-input-inner style-border'>
											<input
												required
												name='name'
												placeholder='Name'
											/>
										</div>
									</div>
									<div className='col-lg-6'>
										<div className='single-input-inner style-border'>
											<input
												required
												name='mobile_number'
												type='tel'
												placeholder='Mobile Number'
											/>
										</div>
									</div>
									<div className='col-lg-12'>
										<div className='single-input-inner style-border'>
											<input
												required
												name='email'
												type='email'
												placeholder='Email'
											/>
										</div>
									</div>
									<div className='col-lg-12'>
										<div className='single-input-inner style-border'>
											<textarea
												required
												name='message'
												placeholder='Message'
											/>
										</div>
									</div>
									<div className='col-12'>
										<button type='submit' className='btn btn-border-gray mt-0'>
											Contact Us
										</button>
									</div>
								</div>
							</form>
							<p className="status-text">{formStatus}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default Contact;
