import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from 'react-scroll-to-top';
// import BlogDetails from 'components/BlogDetails/BlogDetails';
import Home from './Home';

const App = () => {
	useEffect(() => {
		AOS.init({
			offset: 0,
			easing: 'ease',
			once: true,
		});
		AOS.refresh();
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path='/'
					element={<Home />}
				/>
				{/* <Route
					path='blog/:bogId/:postId'
					element={<BlogDetails />}
				/> */}
			</Routes>
			<ScrollToTop
				smooth
				color='#246BFD'
			/>
		</BrowserRouter>
	);
}

export default App;
